import React, { useEffect, useState } from 'react';
import CalculatorCollections from '~/components/Calculators/CalculatorCollections';
import CalculatorFirms from '~/components/Calculators/CalculatorFirms';
import CalculatorPropertyManagement from '~/components/Calculators/CalculatorPropertyManagement';

export default function Calculator({ blok }) {
  const { defaultSelected = 0, hideSelector = false, className="" } = blok;
  const tabs = ['Law Firm', 'Property Manager', 'Collection Agency'];
  const [active, setActive] = useState(tabs[defaultSelected]);

  useEffect(()=>{
    setActive(tabs[blok.defaultSelected]);
  },[blok]);

  return (
    <div className={`flex flex-col items-center ${className}`}>
      {hideSelector !== true ? (
        <div className="tabs flex flex-col text-center sm:text-left sm:flex-row justify-center flex-wrap -ml-6 mb-6 sm:mb-12 mt-6">
          {tabs.map((t,i)=>(
            <div className={`mb-2 sm:mb-0 pl-6 font-medium text-16px select-none`} key={`tab${i}`}>
              <div className={`relative cursor-pointer inline-block ${active === t ? '': 'opacity-50 hover:opacity-100'} transition-opacity duration-100`} onClick={()=>setActive(t)}>
                <div className="mb-1">{t}</div>
                <div className={`absolute inset-x-0 bg-yellow ${active === t ? 'opacity-100': 'opacity-0'}`} style={{height:'3px'}}></div>
              </div>
            </div>
          ))}
        </div>
      ): null}
      <div className="w-full">
        {active === 'Law Firm' ? <CalculatorFirms /> : null}
        {active === 'Property Manager' ? <CalculatorPropertyManagement /> : null}
        {active === 'Collection Agency' ? <CalculatorCollections /> : null}
      </div>
    </div>
  );
}
