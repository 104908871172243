import React, { useEffect } from 'react';
import CurrencyFormat from 'react-currency-format';
import RangeSlider from '~/components/helpers/RangeSlider';
import { useLimitNumber } from '~/hooks/useLimitNumber';
import { kConverter } from '~/utils/kconverter';
import { calculatorGetHourServed, minutesToNearestHour } from '~/utils/calculator-get-hour-served';
import initRangeSlider from '~/utils/init-range-slider';

const CalculatorFirms = () => {
  const minAvgSalary = 35000;
  const maxAvgSalary = 150000;
  const defaultAvgSalary = 90000;
  const minServe = 0;
  const defaultServe = 150;
  const maxServe = 1500;
  const defaultTimePerServe = 60;
  const minTimePerServe = 30;
  const maxTimePerServe = 120;
  const defaultBillable = 225;
  const minBillableRate = 100;
  const maxBillableRate = 300;

  const [avgSalary, setAvgSalary] = useLimitNumber({ limitNumber: defaultAvgSalary, minNumber: minAvgSalary, maxNumber: maxAvgSalary });
  const [serves, setServes] = useLimitNumber({ limitNumber: defaultServe, minNumber: minServe, maxNumber: maxServe });
  const [timePerServe, setTimePerServe] = useLimitNumber({ limitNumber: defaultTimePerServe, minNumber: minTimePerServe, maxNumber: maxTimePerServe });
  const [billableRate, setBillableRate] = useLimitNumber({ limitNumber: defaultBillable, minNumber: minBillableRate, maxNumber: maxBillableRate });

  useEffect(() => {
    initRangeSlider();
  }, [avgSalary, serves, timePerServe, billableRate]);

  return (
    <div className="bg-white py-8 px-4 xl:px-8 calculator w-full shadow-mastheadBox">
      <RangeSlider
        label={`Paralegal average salary+benefits`}
        minVal={minAvgSalary}
        initVal={avgSalary}
        minValLabel={`$${kConverter(minAvgSalary)}`}
        maxVal={maxAvgSalary}
        maxValLabel={`$${kConverter(maxAvgSalary)}`}
        setVal={setAvgSalary}
        type={'currency'}></RangeSlider>

      <RangeSlider label={`Serves per month`} minVal={minServe} initVal={serves} maxVal={maxServe} setVal={setServes}></RangeSlider>

      <RangeSlider label={`Minutes for time spent per serve`} minVal={minTimePerServe} initVal={timePerServe} maxVal={maxTimePerServe} setVal={setTimePerServe}></RangeSlider>
      <RangeSlider label={`Paralegal billable rate`} minVal={minBillableRate} initVal={billableRate} maxVal={maxBillableRate} setVal={setBillableRate}></RangeSlider>
      <div className="mt-2">
        <div className="mb-2 lg:flex">
          <div className="font-bold text-sm">Monthly hours saved:</div>
          <div className="text-accent font-bold lg:ml-auto">{minutesToNearestHour(calculatorGetHourServed(timePerServe, serves))}</div>
        </div>
        <div className="mb-2 lg:flex">
          <div className="font-bold text-sm">Annual paralegal cost savings:</div>
          <div className="text-accent font-bold lg:ml-auto">
            <CurrencyFormat displayType={'text'} prefix={'$'} value={((avgSalary / 120000) * timePerServe * (serves * 12)).toFixed(2)} thousandSeparator={','}></CurrencyFormat>
          </div>
        </div>
        <div className="lg:flex">
          <div className="font-bold text-sm">Annual reallocated billable revenue:</div>
          <div className="text-accent font-bold lg:ml-auto">
            <CurrencyFormat
              displayType={'text'}
              prefix={'$'}
              value={(calculatorGetHourServed(timePerServe, serves) * billableRate).toFixed(2)}
              thousandSeparator={','}></CurrencyFormat>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CalculatorFirms;
