import React, { useEffect } from 'react';
import CurrencyFormat from 'react-currency-format';
import RangeSlider from '~/components/helpers/RangeSlider';
import { useLimitNumber } from '~/hooks/useLimitNumber';
import initRangeSlider from '~/utils/init-range-slider';

const CalculatorCollections = () => {
  const minServeJobs = 25;
  const maxServeJobs = 2500;
  const defaultServeJobs = 250;
  const minPricePerServe = 35;
  const defaultPricePerServe = 50;
  const maxPricePerServe = 150;
  const defaultSuccessRate = 70;
  const minSuccessRate = 20;
  const maxSuccessRate = 100;
  const defaultIncomePerServe = 500;
  const minIncomePerServe = 300;
  const maxIncomePerServe = 1000;

  const [serveJobs, setServeJobs] = useLimitNumber({ limitNumber: defaultServeJobs, minNumber: minServeJobs, maxNumber: maxServeJobs });
  const [pricePerServe, setPricePerServe] = useLimitNumber({ limitNumber: defaultPricePerServe, minNumber: minPricePerServe, maxNumber: maxPricePerServe });
  const [successRate, setSuccessRate] = useLimitNumber({ limitNumber: defaultSuccessRate, minNumber: minSuccessRate, maxNumber: maxSuccessRate });
  const [incomePerServe, setIncomePerServe] = useLimitNumber({ limitNumber: defaultIncomePerServe, minNumber: minIncomePerServe, maxNumber: maxIncomePerServe });

  useEffect(() => {
    initRangeSlider();
  }, [serveJobs, pricePerServe, successRate, incomePerServe]);

  return (
    <div className="bg-white py-8 px-4 xl:px-8 calculator w-full shadow-mastheadBox">
      <RangeSlider
        label={`Current serve jobs per month`}
        minVal={minServeJobs}
        initVal={serveJobs}
        minValLabel={minServeJobs}
        maxVal={maxServeJobs}
        maxValLabel={maxServeJobs}
        setVal={setServeJobs}
      />

      <RangeSlider
        label={`Current price per serve`}
        minValLabel={`$${minPricePerServe}`}
        minVal={minPricePerServe}
        initVal={pricePerServe}
        maxValLabel={`$${maxPricePerServe}`}
        maxVal={maxPricePerServe}
        setVal={setPricePerServe}
        type="currency"
      />

      <RangeSlider
        label={`Current success rate`}
        minVal={minSuccessRate}
        initVal={successRate}
        minValLabel={`${minSuccessRate}%`}
        maxVal={maxSuccessRate}
        maxValLabel={`${maxSuccessRate}%`}
        setVal={setSuccessRate}
        type={'percentage'}
      />

      <RangeSlider
        label={`Current income per successful serve`}
        minValLabel={`$${minIncomePerServe}`}
        minVal={minIncomePerServe}
        initVal={incomePerServe}
        maxValLabel={`$${maxIncomePerServe}`}
        maxVal={maxIncomePerServe}
        setVal={setIncomePerServe}
        type="currency"
      />

      <div className="mt-2">
        <div className="mb-2 lg:flex">
          <div className="font-bold text-sm">Current Net Revenue:</div>
          <div className="text-accent font-bold lg:ml-auto">
            <CurrencyFormat
              displayType={'text'}
              thousandSeparator={','}
              prefix={'$'}
              value={(serveJobs * (successRate / 100) * incomePerServe - pricePerServe * serveJobs).toFixed(2)}
            />
          </div>
        </div>
        <div className="mb-2 lg:flex">
          <div className="font-bold text-sm">Potential Net Revenue with Proof:</div>
          <div className="text-accent font-bold lg:ml-auto">
            <CurrencyFormat
              displayType={'text'}
              prefix={'$'}
              thousandSeparator={','}
              value={(serveJobs * ((successRate / 100) * 0.2 + successRate / 100) * incomePerServe - 85 * serveJobs).toFixed(2)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default CalculatorCollections;
