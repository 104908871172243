import React, { useState } from 'react';
import CurrencyFormat from 'react-currency-format';

const RangeSlider = ({ label, initVal, minVal, minValLabel, maxVal, maxValLabel, setVal, type }) => {
  if (!minValLabel) minValLabel = minVal;
  if (!maxValLabel) maxValLabel = maxVal;
  if (!type) type = 'default';
  const [currentVal, setCurrentVal] = useState(initVal);
  return (
    <div className="mb-6">
      <div className="flex flex-col sm:flex-row gap-4 items-center justify-between bg-semi-grey py-3 px-4 rounded-md mb-4 lg:mb-2">
        <label htmlFor={label.toLocaleLowerCase().replace(/\s/g, '-')} className="text-center sm:text-left no-style calculator-label text-sm">
          {label}:
        </label>
        {type === 'currency' ? (
          <CurrencyFormat
            id={label.toLocaleLowerCase().replace(/\s/g, '-')}
            className="calculator-input xl:text-lg py-1"
            prefix={'$'}
            value={currentVal}
            onValueChange={val => {
              if (val.floatValue > maxVal) {
                val.value = maxVal;
              }
              setVal(val.value);
              setCurrentVal(val.value);
            }}
            thousandSeparator={','}
          />
        ) : type === 'percentage' ? (
          <CurrencyFormat
            id={label.toLocaleLowerCase().replace(/\s/g, '-')}
            className="calculator-input xl:text-lg py-1"
            suffix={'%'}
            value={currentVal}
            onValueChange={val => {
              if (val.floatValue > maxVal) {
                val.value = maxVal;
              }
              setVal(val.value);
              setCurrentVal(val.value);
            }}
          />
        ) : (
          <input
            id={label.toLocaleLowerCase().replace(/\s/g, '-')}
            type="number"
            className="calculator-input appearance-none text-md xl:text-lg py-1"
            value={currentVal}
            onChange={e => {
              setVal(e.target.value);
              setCurrentVal(e.target.value);
            }}
          />
        )}
      </div>
      <div className="flex items-center gap-1 lg:gap-4">
        <div className="font-bold range-label">{minValLabel}</div>
        <input
          type="range"
          value={currentVal}
          onChange={e => {

            setVal(e.target.value);
            setCurrentVal(e.target.value);
          }}
          className={'range-slider slider-progress flex-auto w-auto'}
          min={minVal}
          max={maxVal}
        />
        <div className="font-bold range-label text-right">{maxValLabel}</div>
      </div>
    </div>
  );
};

export default RangeSlider;
