import React, { useEffect } from 'react';
import CurrencyFormat from 'react-currency-format';
import RangeSlider from '~/components/helpers/RangeSlider';
import { useLimitNumber } from '~/hooks/useLimitNumber';
import initRangeSlider from '~/utils/init-range-slider';

const CalculatorPropertyManagement = () => {
  const minPostPerMonth = 10;
  const maxPostPerMonth = 2000;
  const defaultPostPerMonth = 300;
  const minCostPerPost = 5;
  const defaultCostPerPost = 15;
  const maxCostPerPost = 100;
  const defaultResponseRate = 50;
  const minResponseRate = 0;
  const maxResponseRate = 100;
  const defaultIncomePerResponse = 300;
  const minIncomePerResponse = 100;
  const maxIncomePerResponse = 2500;

  const [postPerMonth, setPostPerMonth] = useLimitNumber({ limitNumber: defaultPostPerMonth, minNumber: minPostPerMonth, maxNumber: maxPostPerMonth });
  const [costPerPost, setCostPerPost] = useLimitNumber({ limitNumber: defaultCostPerPost, minNumber: minCostPerPost, maxNumber: maxCostPerPost });
  const [responseRate, setResponseRate] = useLimitNumber({ limitNumber: defaultResponseRate, minNumber: minResponseRate, maxNumber: maxResponseRate });
  const [incomePerResponse, setIncomePerResponse] = useLimitNumber({ limitNumber: defaultIncomePerResponse, minNumber: minIncomePerResponse, maxNumber: maxIncomePerResponse });

  useEffect(() => {
    initRangeSlider();
  }, [postPerMonth, costPerPost, responseRate, incomePerResponse]);

  const percentOf = (number = 0, percentage = 100) => (number / 100) * percentage;
  const respondedPosts = percentOf(postPerMonth, responseRate);
  const incomeFromRespondedPosts = incomePerResponse * respondedPosts;
  const additionalRevenueForPosts = (incomeFromRespondedPosts + percentOf(incomeFromRespondedPosts, 10) - postPerMonth * costPerPost).toFixed(2);

  return (
    <div className="bg-white py-8 px-4 xl:px-8 calculator w-full shadow-mastheadBox">
      <RangeSlider label={`Posts per month`} minVal={minPostPerMonth} initVal={postPerMonth} maxVal={maxPostPerMonth} setVal={setPostPerMonth}></RangeSlider>
      <RangeSlider
        label={`Costs per post`}
        minVal={minCostPerPost}
        initVal={costPerPost}
        minValLabel={`$${minCostPerPost}`}
        maxVal={maxCostPerPost}
        maxValLabel={`$${maxCostPerPost}`}
        setVal={setCostPerPost}
        type={'currency'}></RangeSlider>

      <RangeSlider
        label={`Response rate`}
        minVal={minResponseRate}
        initVal={responseRate}
        minValLabel={`${minResponseRate}%`}
        maxVal={maxResponseRate}
        maxValLabel={`${maxResponseRate}%`}
        setVal={setResponseRate}
        type={'percentage'}></RangeSlider>

      <RangeSlider
        label={`Income per successful response`}
        minVal={minIncomePerResponse}
        initVal={incomePerResponse}
        minValLabel={`$${minIncomePerResponse}`}
        maxVal={maxIncomePerResponse}
        maxValLabel={`$${maxIncomePerResponse}`}
        setVal={setIncomePerResponse}
        type={'currency'}></RangeSlider>
      <div className="mt-2">
        <div className="mb-2 lg:flex">
          <div className="font-bold text-sm">Hours Saved Each Month:</div>
          <div className="lg:ml-auto">
            <div className="text-accent font-bold lg:text-right">{(postPerMonth * 30) / 60}</div>
            <div className="text-xs text-dark-blue lg:text-right flex" style={{ opacity: '0.4' }}>
              # of posts x 30 minutes / 60
            </div>
          </div>
        </div>
        <div className="mb-2 lg:flex">
          <div className="font-bold text-sm" style={{ maxWidth: '50%' }}>
            Additional Monthly Revenue Generated for Posts:
          </div>
          <div className="lg:ml-auto">
            <div className="text-accent font-bold lg:text-right">
              <CurrencyFormat value={additionalRevenueForPosts} thousandSeparator="," displayType={'text'} prefix={'$'} />
            </div>
            <div className="text-xs text-dark-blue lg:text-right" style={{ opacity: '0.4' }}>
              income x responded posts + 10% - cost per post
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalculatorPropertyManagement;
