import React, { useState, useCallback } from 'react';

export const useLimitNumber = ({ limitNumber, minNumber, maxNumber }) => {
  const [number, setNumber] = useState(limitNumber);

  const setLimitNumber = useCallback(Lnumber => {
    Lnumber < minNumber ? setNumber(minNumber) : Lnumber > maxNumber ? setNumber(maxNumber) : setNumber(Lnumber);
  }, []);

  return [number, setLimitNumber];
};
