export default function initRangeSlider() {
  function setProperty(e) {
    e.style.setProperty('--value', e.value);
  }
  for (let e of document.querySelectorAll('input[type="range"].slider-progress')) {
    e.removeEventListener('input', setProperty);
    e.removeEventListener('change', setProperty);
    e.style.setProperty('--value', e.value);
    e.style.setProperty('--min', e.min == '' ? '0' : e.min);
    e.style.setProperty('--max', e.max == '' ? '100' : e.max);
    e.addEventListener('input', () => setProperty(e));
    e.addEventListener('change', () => setProperty(e));
  }
}
